import React from "react";
import { useTranslation } from "react-i18next";
import tw, { css } from "twin.macro";

import SearchIcon from "../../../static/icons/search.svg";
import ExpertSearchIcon from "../../../static/images/expert-search-icon.svg";
import MaterialSourcingIcon from "../../../static/images/material-sourcing.svg";
import SystemSearchIcon from "../../../static/images/system-search-icon.svg";
import Anchor from "../../components/Anchor";
import Card from "../../components/Card";
import { Container } from "../../components/Container";
import { useExpertSearchUrl, useSystemSearchUrl } from "../../utils/common";

const searchCards = css`
  ${tw`grid grid-cols-1 sm:grid-cols-3 mt-0 md:w-[50rem]`}
  >a {
    ${tw`w-full p-0 text-turquoise w-full hover:(text-turquoise-dark)`}
    >div {
      ${tw`hover:shadow-xl motion-safe:hover:scale-105`}
    }
  }
  p {
    ${tw`mt-2 mb-2 text-xl`}
    >p {
      ${tw`mt-8`}
    }
    > div {
      > p {
        ${tw`flex inline-flex`}
      }
      > svg {
        ${tw`w-10 h-10 mx-auto mt-2`}
      }
    }
  }
  svg {
    ${tw`mx-auto fill-current`}
    >g {
      ${tw`stroke-current`}
    }
  }
`;

const SearchCards: React.FC = ({ ...rest }) => {
  const { t } = useTranslation();
  const systemSearch = useSystemSearchUrl();
  const expertSearch = useExpertSearchUrl();

  return (
    <Container {...rest}>
      <section>
        <article css={searchCards}>
          <Anchor href={systemSearch}>
            <Card key={1}>
              <SystemSearchIcon tw="w-40 h-40" />
              <div tw="flex flex-col items-center w-auto">
                <p tw="break-normal">{t("index.searchButtons.systems")}</p>
                <SearchIcon tw="w-10 h-10" />
              </div>
            </Card>
          </Anchor>
          <Anchor href={expertSearch}>
            <Card key={2}>
              <ExpertSearchIcon tw="w-40 h-40" />
              <div tw="flex flex-col items-center">
                <p tw="break-normal">{t("index.searchButtons.experts")}</p>
                <SearchIcon tw="w-10 h-10" />
              </div>
            </Card>
          </Anchor>
          <Anchor href={"/material-sourcing"}>
            <Card key={3}>
              <MaterialSourcingIcon tw="w-40 h-40" />
              <div tw="flex flex-col items-center w-auto">
                <p tw="break-normal">{t("index.searchButtons.materialSourcing")}</p>
                <SearchIcon tw="w-10 h-10" />
              </div>
            </Card>
          </Anchor>
        </article>
      </section>
    </Container>
  );
};

export default SearchCards;
